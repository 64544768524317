import { FC, memo, useRef, useEffect } from 'react';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import { RegistrationSection } from './components/RegistrationSection/RegistrationSection';
import { useRegistrationData } from './hooks/useRegistrationData';
import styles from './RegistrationPage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const RegistrationPage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const registrationPageRef = useRef(null);
  const isSignUpPageActive = 'signup' === currentlyViewedPage;
  useAnimateAppearing(isSignUpPageActive, registrationPageRef);
  const { clearAllRegistrationData, isRegistrationDataEmpty } = useRegistrationData();
  useEffect(() => {
    !isSignUpPageActive && !isRegistrationDataEmpty() && clearAllRegistrationData();
  }, [clearAllRegistrationData, isRegistrationDataEmpty, isSignUpPageActive]);
  return (
    <div className={styles.RegistrationPage} ref={registrationPageRef}>
      <RegistrationSection />
    </div>
  );
});
