import { FC, memo } from 'react';

import { RegistrationQuestionType } from 'types/types';
import styles from './RegistrationApplyButton.module.scss';

interface Props {
  type?: RegistrationQuestionType;
  validationError?: string;
  isValidatedFieldEmpty?: boolean;
}

export const RegistrationApplyButton: FC<Props> = memo(
  ({ type, validationError, isValidatedFieldEmpty }) => {
    const isDisabled = !!validationError || isValidatedFieldEmpty;
    return (
      <button type="button" className={styles.RegistrationApplyButton} disabled={isDisabled}>
        <span>OK</span>
      </button>
    );
  },
);
