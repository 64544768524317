import { FC, memo, useRef } from 'react';

import { MainLogoLive } from 'components/MainLogoLive/MainLogoLive';
import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import styles from './HomePage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const HomePage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const homePageRef = useRef(null);
  useAnimateAppearing('home' === currentlyViewedPage, homePageRef);
  return (
    <div className={styles.HomePage} ref={homePageRef}>
      <MainLogoLive />
    </div>
  );
});
