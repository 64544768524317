import { FC, cloneElement, Children, memo } from 'react';

import { ReactChildrenType, RegistrationQuestionType } from 'types/types';
import styles from './RegistrationApplyWrapper.module.scss';

interface Props {
  children?: ReactChildrenType;
  type: RegistrationQuestionType;
  validationError: string;
  isValidatedFieldEmpty: boolean;
}

export const RegistrationApplyWrapper: FC<Props> = memo(
  ({ children, type, validationError, isValidatedFieldEmpty }) => {
    return (
      <div className={styles.RegistrationApplyWrapper}>
        {Children.map(children, (child) =>
          cloneElement(child, {
            type,
            validationError,
            isValidatedFieldEmpty,
          }),
        )}
      </div>
    );
  },
);
