import { EMPTY_STRING } from 'constants/constants';
import { CurrentLanguageType, RegistrationDataType, RegistrationQuestionType } from 'types/types';
import { language } from 'utils/translation';

export interface IValidationErrorData {
  isValidatedFieldEmpty: boolean;
  validationError: string;
}

export const getEmailValidationError = (
  email: string,
  currentLanguage: CurrentLanguageType,
): IValidationErrorData => {
  if (!email) return { isValidatedFieldEmpty: true, validationError: EMPTY_STRING };
  const emailValidationError = language(currentLanguage, 'specifiedEmailIncorrect');
  if (email.length < 8)
    return { validationError: emailValidationError, isValidatedFieldEmpty: false };
  const pattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email.match(pattern))
    return { validationError: emailValidationError, isValidatedFieldEmpty: false };
  return { isValidatedFieldEmpty: false, validationError: EMPTY_STRING };
};

export const getValidationError = (
  registrationData: RegistrationDataType,
  type: RegistrationQuestionType,
  currentLanguage: CurrentLanguageType,
): IValidationErrorData => {
  const emailValidationErrorData = getEmailValidationError(registrationData.email, currentLanguage);
  switch (type) {
    case 'email':
      return emailValidationErrorData;
    case 'nickname':
      return;
    case 'password':
      return;
  }
};

export const getRegistrationQuestionText = (type: RegistrationQuestionType) => {
  switch (type) {
    case 'email':
      return 'registrationQuestionEmail';
    case 'password':
      return 'registrationQuestionPassword';
    case 'nickname':
      return 'registrationQuestionNickname';
  }
};
