import { RootState } from 'redux/store';

import { mainSlice } from './mainSlice';

// setters
export const {
  actions: {
    setCurrentLanguage,
    setTheme,
    setIsFirstLoad,
    setIsAuthModalActive,
    setIsContentContainerHovered,
    setIsUsersSearchResultsShown,
    setUserSearchKey,
    setFoundUsers,
    setActiveHealthSection,
    setActivePetsSection,
    setActiveSportSection,
    setIsPasswordRecoveryMode,
    setPasswordRecoveringEmail,
    setIsPasswordRecoveryProcessStarted,
    setOnlineUsersNicknames,
    setCurrentlyViewedAvailableActions,
    setOpenedAssessmentRegistration,
    setIsLanguageSwitcherRolled,
    setCurrentlyViewedPage,
    setCurrentRegistrationQuestionNumber,
    setIsRegistrationMayProceed,
  },
} = mainSlice;

// getters
export const getCurrentLanguage = ({ main: { currentLanguage } }: RootState) => currentLanguage;
export const getTheme = ({ main: { theme } }: RootState) => theme;
export const getCurrentlyViewedPage = ({ main: { currentlyViewedPage } }: RootState) =>
  currentlyViewedPage;
export const getIsLanguageSwitcherRolled = ({ main: { isLanguageSwitcherRolled } }: RootState) =>
  isLanguageSwitcherRolled;
export const getIsFirstLoad = ({ main: { isFirstLoad } }: RootState) => isFirstLoad;
export const getIsAuthModalActive = ({ main: { isAuthModalActive } }: RootState) =>
  isAuthModalActive;
export const getIsContentContainerHovered = ({ main: { isContentContainerHovered } }: RootState) =>
  isContentContainerHovered;
export const getIsUsersSearchResultsShown = ({ main: { isUsersSearchResultsShown } }: RootState) =>
  isUsersSearchResultsShown;
export const getUserSearchKey = ({ main: { userSearchKey } }: RootState) => userSearchKey;
export const getFoundUsers = ({ main: { foundUsers } }: RootState) => foundUsers;
export const getSearchUsersStatus = ({ main: { searchUsersStatus } }: RootState) =>
  searchUsersStatus;
export const getActiveSportSection = ({ main: { activeSportSection } }: RootState) =>
  activeSportSection;
export const getActivePetsSection = ({ main: { activePetsSection } }: RootState) =>
  activePetsSection;
export const getActiveHealthSection = ({ main: { activeHealthSection } }: RootState) =>
  activeHealthSection;
export const getIsPasswordRecoveryMode = ({ main: { isPasswordRecoveryMode } }: RootState) =>
  isPasswordRecoveryMode;
export const getPasswordRecoveringEmail = ({ main: { passwordRecoveringEmail } }: RootState) =>
  passwordRecoveringEmail;
export const getIsPasswordRecoveryProcessStarted = ({
  main: { isPasswordRecoveryProcessStarted },
}: RootState) => isPasswordRecoveryProcessStarted;
export const getOnlineUsersNicknames = ({ main: { onlineUsersNicknames } }: RootState) =>
  onlineUsersNicknames;
export const getCurrentlyViewedAvailableActions = ({
  main: { currentlyViewedAvailableActions },
}: RootState) => currentlyViewedAvailableActions;
export const getOpenedAssessmentRegistration = ({
  main: { openedAssessmentRegistration },
}: RootState) => openedAssessmentRegistration;
export const getCurrentRegistrationQuestionNumber = ({
  main: { currentRegistrationQuestionNumber },
}: RootState) => currentRegistrationQuestionNumber;
export const getIsRegistrationMayProceed = ({ main: { isRegistrationMayProceed } }: RootState) =>
  isRegistrationMayProceed;
