import { FC, ReactNode, useRef } from 'react';

import styles from './MainLogoLettersWrapper.module.scss';
import { useAnimate } from './hooks/useAnimate';

interface Props {
  children: ReactNode;
}

export const MainLogoLettersWrapper: FC<Props> = ({ children }) => {
  const mainLogoLettersWrapperRef = useRef<HTMLDivElement>(null);
  useAnimate(mainLogoLettersWrapperRef);
  return (
    <div className={styles.MainLogoLettersWrapper} ref={mainLogoLettersWrapperRef}>
      {children}
    </div>
  );
};
