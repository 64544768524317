import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateExtension = (
  isCurrentQuestionOrAlreadyAnswered: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const width = !isCurrentQuestionOrAlreadyAnswered ? 0 : 100;
  const {
    timeline: extensionTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isCurrentQuestionOrAlreadyAnswered]);
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : 1;
  extensionTimeline &&
    extensionTimeline.to(scopeElement.current, {
      width: `${width}%`,
      duration,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
};
