import { FC, memo } from 'react';
import { useAppSelector } from 'redux/hooks';

import { TextStyled } from 'components/TextStyled/TextStyled';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { language } from 'utils/translation';
import { RegistrationQuestionAnswer } from './components/RegistrationQuestionAnswer/RegistrationQuestionAnswer';
import { RegistrationDataValidationInfo } from './components/RegistrationDataValidationInfo/RegistrationDataValidationInfo';
import { RegistrationQuestionType } from 'types/types';
import { RegistrationApplyWrapper } from './components/RegistrationApplyWrapper/RegistrationApplyWrapper';
import { RegistrationApplyButton } from './components/RegistrationApplyButton/RegistrationApplyButton';
import { getRegistrationQuestionText, getValidationError } from './utils';
import { getRegistrationData } from 'redux/slices/user/exports';
import styles from './RegistrationQuestion.module.scss';

interface Props {
  type: RegistrationQuestionType;
}

export const RegistrationQuestion: FC<Props> = memo(({ type }) => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const registrationData = useAppSelector(getRegistrationData);
  const { validationError, isValidatedFieldEmpty } = getValidationError(
    registrationData,
    type,
    currentLanguage,
  );
  const registrationQuestionText = getRegistrationQuestionText(type);
  return (
    <div className={styles.RegistrationQuestion}>
      <TextStyled text={language(currentLanguage, registrationQuestionText)} />
      <RegistrationApplyWrapper
        type={type}
        validationError={validationError}
        isValidatedFieldEmpty={isValidatedFieldEmpty}
      >
        <RegistrationQuestionAnswer />
        <RegistrationApplyButton />
      </RegistrationApplyWrapper>
      <RegistrationDataValidationInfo validationError={validationError} />
    </div>
  );
});
