import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import jwtDecode from 'jwt-decode';

import {
  CurrentLanguageType,
  ILocalStorageSave,
  ISystemMessageObject,
  Nullable,
  Undefinable,
  RoleType,
  IAssessmentData,
  RegistrationDataType,
} from 'types/types';
import {
  checkEmailAvailabilityAsync,
  userAuthCheckAsync,
  userLoginAsync,
  userRegistrationAsync,
  updateUserAvatarAsync,
  updateUserSpecializationAsync,
  updateUserPasswordAsync,
  updateUserRoleAsync,
  updateUserInformationAsync,
  deleteUserAccountAsync,
  recoverUserPasswordAsync,
  checkPasswordRecoveringCodeAsync,
  recoveryUserPasswordChangeAsync,
  updateUserMasteryAsync,
} from '../../thunks/userThunks';
import {
  IFeedbackData,
  IGetAllFeedbacksResponse,
  IGetOneUserResponse,
  ITokenDecodeData,
} from 'redux/thunks/types';
import {
  getAssessmentsToApproveAsync,
  handleAssessmentsApprovementAsync,
  removeAssessmentAsync,
} from 'redux/thunks/assessmentsThunks';
import { UserState } from './types';
import { userSliceInitialState as initialState } from './initialState';
import {
  addOneFeedbackAsync,
  deleteAllFeedbacksAsync,
  deleteOneFeedbackAsync,
  getAllFeedbacksAsync,
} from 'redux/thunks/feedbacksThunks';

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthMessage(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Nullable<ISystemMessageObject>>,
    ) {
      state.authMessage = payload;
    },
    setIsAuth(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isAuth = payload;
    },
    setToken(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<string>>) {
      state.token = payload;
    },
    setUserId(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<number>>) {
      state.userId = payload;
    },
    setUserEmail(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<string>>) {
      state.userEmail = payload;
    },
    setUserNickname(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<string>>) {
      state.userNickname = payload;
    },
    setUserRole(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<RoleType>>) {
      state.userRole = payload;
    },
    setIsEmailChecking(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isEmailChecking = payload;
    },
    setSelectedRegistrationEmil(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<string>,
    ) {
      state.selectedRegistrationEmail = payload;
    },
    setIsDoctor(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isDoctor = payload;
    },
    setIsPatient(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isPatient = payload;
    },
    setIsSportsmen(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isSportsmen = payload;
    },
    setIsTrainer(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isTrainer = payload;
    },
    setIsPetOwner(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isPetOwner = payload;
    },
    setIsVeterinarian(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isVeterinarian = payload;
    },
    setUserBirthDateTimestamp(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Nullable<number>>,
    ) {
      state.userBirthDateTimestamp = payload;
    },
    setUserCity(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<string>>) {
      state.userCity = payload;
    },
    setUserCountry(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<string>>) {
      state.userCountry = payload;
    },
    setUserFirstName(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Nullable<string>>,
    ) {
      state.userFirstName = payload;
    },
    setUserLastName(state: WritableDraft<UserState>, { payload }: PayloadAction<Nullable<string>>) {
      state.userLastName = payload;
    },
    setUserAvatar(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Undefinable<string>>,
    ) {
      state.userAvatar = payload;
    },
    setIsEmailConfirmed(state: WritableDraft<UserState>, { payload }: PayloadAction<boolean>) {
      state.isEmailConfirmed = payload;
    },
    setUserRestrictionsLevel(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Undefinable<number>>,
    ) {
      state.userRestrictionsLevel = payload;
    },
    setUserRestrictionsTimestamp(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Undefinable<number>>,
    ) {
      state.userRestrictionsTimestamp = payload;
    },
    setRecoveryCodeTimerTimestamp(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Nullable<number>>,
    ) {
      state.recoveryCodeTimerTimestamp = payload;
    },
    setRecoverSessionTimerTimestamp(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Nullable<number>>,
    ) {
      state.recoverSessionTimerTimestamp = payload;
    },
    setFeedbacks(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Array<IFeedbackData>>,
    ) {
      state.feedbacks = payload;
    },
    setRecoverySessionId(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Nullable<string>>,
    ) {
      state.recoverySessionId = payload;
    },
    setMyAssessments(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Array<IAssessmentData>>,
    ) {
      state.myAssessments = payload;
    },
    setAssessmentsToApprove(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<Array<IAssessmentData>>,
    ) {
      state.assessmentsToApprove = payload;
    },
    setRegistrationData(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<RegistrationDataType>,
    ) {
      state.registrationData = payload;
    },
    setRegistrationDataApplied(
      state: WritableDraft<UserState>,
      { payload }: PayloadAction<RegistrationDataType>,
    ) {
      state.registrationDataApplied = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // check the registration email availability
      .addCase(checkEmailAvailabilityAsync.pending, (state) => {
        state.authMessage = null;
        state.isEmailChecking = true;
        state.authStatus = 'loading';
      })
      .addCase(checkEmailAvailabilityAsync.fulfilled, (state, { payload }) => {
        state.isEmailChecking = false;
        state.authStatus = 'idle';
        if (payload) {
          if (payload.availableEmail) {
            state.selectedRegistrationEmail = payload.availableEmail;
            state.authMessage = {
              message: payload.message,
              severity: 'positive',
            };
          } else {
            state.authMessage = {
              message: payload.message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(checkEmailAvailabilityAsync.rejected, (state, { error }) => {
        state.isEmailChecking = false;
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // register a new user
      .addCase(userRegistrationAsync.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(userRegistrationAsync.fulfilled, (state, { payload }) => {
        state.authStatus = 'idle';
        if (payload) {
          if (payload.token) {
            const tokenDecodeData: ITokenDecodeData = jwtDecode(payload.token);
            state.userId = tokenDecodeData.id;
            state.userEmail = tokenDecodeData.email;
            state.userNickname = tokenDecodeData.nickname;
            state.userRole = tokenDecodeData.role;
            state.isAuth = true;
            state.token = payload.token;
          }
          state.authMessage = {
            message: payload.message,
            severity: payload.token ? 'positive' : 'negative',
          };
        }
      })
      .addCase(userRegistrationAsync.rejected, (state, { error }) => {
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // login an user
      .addCase(userLoginAsync.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(userLoginAsync.fulfilled, (state, { payload }) => {
        state.authStatus = 'idle';
        if (payload) {
          if (payload.token) {
            const tokenDecodeData: ITokenDecodeData = jwtDecode(payload.token);
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            state.userId = tokenDecodeData.id;
            state.userEmail = userData.email;
            state.userNickname = userData.nickname;
            state.userRole = userData.role;
            state.isAuth = true;
            state.token = payload.token;
            state.userFirstName = userData?.firstName || null;
            state.userLastName = userData?.lastName || null;
            state.userCity = userData?.city || null;
            state.userCountry = userData?.country || null;
            state.userBirthDateTimestamp = userData?.birthDateTimestamp;
            state.userAvatar = (userData?.avatar as string) || undefined;
            state.isEmailConfirmed = userData?.isConfirmed || false;
            state.userRestrictionsLevel = userData?.restrictionsLevel;
            state.userRestrictionsTimestamp = userData?.restrictionsTimestamp;
            state.isDoctor = userData?.isDoctor || false;
            state.isPatient = userData?.isPatient || false;
            state.isVeterinarian = userData?.isVeterinarian || false;
            state.isPetOwner = userData?.isPetOwner || false;
            state.isSportsmen = userData?.isSportsmen || false;
            state.isTrainer = userData?.isTrainer || false;
            state.myAssessments = userData?.assessments || [];
          }
          !payload.token &&
            (state.authMessage = {
              message: payload.message,
              severity: 'negative',
            });
        }
      })
      .addCase(userLoginAsync.rejected, (state, { error }) => {
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // updates the user authorization token
      .addCase(userAuthCheckAsync.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(userAuthCheckAsync.fulfilled, (state, { payload }) => {
        state.authStatus = 'idle';
        if (payload) {
          if (payload.token) {
            const tokenDecodeData: ITokenDecodeData = jwtDecode(payload.token);
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            state.userId = tokenDecodeData.id;
            state.userEmail = userData.email;
            state.userNickname = userData.nickname;
            state.userRole = userData.role;
            state.isAuth = true;
            state.token = payload.token;
            state.userFirstName = userData?.firstName || null;
            state.userLastName = userData?.lastName || null;
            state.userCity = userData?.city || null;
            state.userCountry = userData?.country || null;
            state.userBirthDateTimestamp = userData?.birthDateTimestamp;
            state.userAvatar = (userData?.avatar as string) || undefined;
            state.isEmailConfirmed = userData?.isConfirmed || false;
            state.userRestrictionsLevel = userData?.restrictionsLevel;
            state.userRestrictionsTimestamp = userData?.restrictionsTimestamp;
            state.isDoctor = userData?.isDoctor || false;
            state.isPatient = userData?.isPatient || false;
            state.isVeterinarian = userData?.isVeterinarian || false;
            state.isPetOwner = userData?.isPetOwner || false;
            state.isSportsmen = userData?.isSportsmen || false;
            state.isTrainer = userData?.isTrainer || false;
            state.myAssessments = userData?.assessments || [];
          } else {
            state.token = null;
            let currentLanguage: CurrentLanguageType = 'en';
            const save: Nullable<string> = localStorage.getItem('deguz-save');
            if (save) {
              const saveData: ILocalStorageSave = JSON.parse(save);
              if (saveData.currentLanguage) {
                currentLanguage = saveData.currentLanguage;
              }
              if (saveData.token) {
                delete saveData.token;
                localStorage.setItem('deguz-save', JSON.stringify(saveData));
              }
            }
            if (
              payload.message === 'jwt expired' ||
              payload.message === 'jwt malformed' ||
              payload.message === 'invalid token'
            ) {
              if (payload.message === 'jwt expired') {
                state.authMessage = {
                  message:
                    currentLanguage === 'en'
                      ? 'The authorization token has expired'
                      : 'Токен авторизации истёк',
                  severity: 'negative',
                };
              }
              if (payload.message === 'jwt malformed' || payload.message === 'invalid token') {
                state.authMessage = {
                  message:
                    currentLanguage === 'en'
                      ? 'The authorization token is incorrect'
                      : 'Токен авторизации - некорректный',
                  severity: 'negative',
                };
              }
            } else {
              state.authMessage = {
                message: payload.message,
                severity: 'negative',
              };
            }
          }
        }
      })
      .addCase(userAuthCheckAsync.rejected, (state, { error }) => {
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // update specified user avatar
      .addCase(updateUserAvatarAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserAvatarAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            state.userAvatar = (userData.avatar as string) || undefined;
            state.authMessage = {
              message,
              severity: 'positive',
            };
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(updateUserAvatarAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // update specified user specialization
      .addCase(updateUserSpecializationAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserSpecializationAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            typeof userData?.isPatient === 'boolean' && (state.isPatient = userData.isPatient);
            typeof userData?.isPetOwner === 'boolean' && (state.isPetOwner = userData.isPetOwner);
            typeof userData?.isSportsmen === 'boolean' &&
              (state.isSportsmen = userData.isSportsmen);
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(updateUserSpecializationAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // update specified user mastery
      .addCase(updateUserMasteryAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserMasteryAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            typeof userData?.isTrainer === 'boolean' && (state.isTrainer = userData.isTrainer);
            typeof userData?.isDoctor === 'boolean' && (state.isDoctor = userData.isDoctor);
            typeof userData?.isVeterinarian === 'boolean' &&
              (state.isVeterinarian = userData.isVeterinarian);
            state.userRole !== 'CREATOR' &&
              userData?.assessments?.length &&
              (state.myAssessments = userData.assessments);
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(updateUserMasteryAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // update the user password
      .addCase(updateUserPasswordAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserPasswordAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          state.authMessage = {
            message,
            severity: isSuccess ? 'positive' : 'negative',
          };
        }
      })
      .addCase(updateUserPasswordAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // update specified user role
      .addCase(updateUserRoleAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserRoleAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            userData?.role && (state.userRole = userData.role);
            state.authMessage = {
              message,
              severity: 'positive',
            };
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(updateUserRoleAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // update specified user information
      .addCase(updateUserInformationAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUserInformationAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            userData?.email && (state.userEmail = userData.email);
            userData?.nickname && (state.userNickname = userData.nickname);
            typeof userData?.firstName !== 'undefined' &&
              (state.userFirstName = userData.firstName);
            typeof userData?.lastName !== 'undefined' && (state.userLastName = userData.lastName);
            typeof userData?.city !== 'undefined' && (state.userCity = userData.city);
            typeof userData?.country !== 'undefined' && (state.userCountry = userData.country);
            typeof userData?.birthDateTimestamp !== 'undefined' &&
              (state.userBirthDateTimestamp = userData.birthDateTimestamp);
            state.authMessage = {
              message,
              severity: 'positive',
            };
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(updateUserInformationAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // delete specified user
      .addCase(deleteUserAccountAsync.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(deleteUserAccountAsync.fulfilled, (state, { payload }) => {
        state.updateStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          state.authMessage = {
            message,
            severity: isSuccess ? 'positive' : 'negative',
          };
        }
      })
      .addCase(deleteUserAccountAsync.rejected, (state, { error }) => {
        state.updateStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // recover user password
      .addCase(recoverUserPasswordAsync.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(recoverUserPasswordAsync.fulfilled, (state, { payload }) => {
        state.authStatus = 'idle';
        if (payload) {
          const { statusCode, message, recoveryCodeTimerTimestamp } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess && recoveryCodeTimerTimestamp) {
            state.recoveryCodeTimerTimestamp = recoveryCodeTimerTimestamp;
          }
          state.authMessage = {
            message,
            severity: isSuccess ? 'positive' : 'negative',
          };
        }
      })
      .addCase(recoverUserPasswordAsync.rejected, (state, { error }) => {
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // check password recovering code
      .addCase(checkPasswordRecoveringCodeAsync.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(checkPasswordRecoveringCodeAsync.fulfilled, (state, { payload }) => {
        state.authStatus = 'idle';
        if (payload) {
          const { statusCode, message, recoverSessionTimerTimestamp, recoverySessionId } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess && recoverSessionTimerTimestamp) {
            state.recoverSessionTimerTimestamp = recoverSessionTimerTimestamp;
            state.recoverySessionId = recoverySessionId;
            state.recoveryCodeTimerTimestamp && (state.recoveryCodeTimerTimestamp = null);
          }
          state.authMessage = {
            message,
            severity: isSuccess ? 'positive' : 'negative',
          };
        }
      })
      .addCase(checkPasswordRecoveringCodeAsync.rejected, (state, { error }) => {
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // change user password in recovery mode
      .addCase(recoveryUserPasswordChangeAsync.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(recoveryUserPasswordChangeAsync.fulfilled, (state, { payload }) => {
        state.authStatus = 'idle';
        if (payload) {
          if (payload.token) {
            const tokenDecodeData: ITokenDecodeData = jwtDecode(payload.token);
            const fullUserData = payload as IGetOneUserResponse;
            const { userData } = fullUserData;
            state.userId = tokenDecodeData.id;
            state.userEmail = userData.email;
            state.userNickname = userData.nickname;
            state.userRole = userData.role;
            state.isAuth = true;
            state.token = payload.token;
            state.userFirstName = userData?.firstName || null;
            state.userLastName = userData?.lastName || null;
            state.userCity = userData?.city || null;
            state.userCountry = userData?.country || null;
            state.userBirthDateTimestamp = userData?.birthDateTimestamp;
            state.userAvatar = (userData?.avatar as string) || undefined;
            state.isEmailConfirmed = userData?.isConfirmed || false;
            state.userRestrictionsLevel = userData?.restrictionsLevel;
            state.userRestrictionsTimestamp = userData?.restrictionsTimestamp;
            state.isDoctor = userData?.isDoctor || false;
            state.isPatient = userData?.isPatient || false;
            state.isVeterinarian = userData?.isVeterinarian || false;
            state.isPetOwner = userData?.isPetOwner || false;
            state.isSportsmen = userData?.isSportsmen || false;
            state.isTrainer = userData?.isTrainer || false;
            state.myAssessments = userData?.assessments || [];
          }
          state.authMessage = {
            message: payload.message,
            severity: payload.token ? 'positive' : 'negative',
          };
        }
      })
      .addCase(recoveryUserPasswordChangeAsync.rejected, (state, { error }) => {
        state.authStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // get all available assessments to approve
      .addCase(getAssessmentsToApproveAsync.pending, () => {})
      .addCase(getAssessmentsToApproveAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const { statusCode, foundAssessments } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess && foundAssessments) {
            state.assessmentsToApprove = foundAssessments;
          }
        }
      })
      .addCase(getAssessmentsToApproveAsync.rejected, (state, { error }) => {
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // handle the assessment's approvement
      .addCase(handleAssessmentsApprovementAsync.pending, (state) => {
        state.processingStatus = 'loading';
      })
      .addCase(handleAssessmentsApprovementAsync.fulfilled, (state, { payload }) => {
        state.processingStatus = 'idle';
        if (payload) {
          const { statusCode } = payload;
          const isSuccess = statusCode === 200;
          if (!isSuccess) {
            state.authMessage = {
              message: payload.message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(handleAssessmentsApprovementAsync.rejected, (state, { error }) => {
        state.processingStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // remove the assessment
      .addCase(removeAssessmentAsync.pending, (state) => {
        state.processingStatus = 'loading';
      })
      .addCase(removeAssessmentAsync.fulfilled, (state, { payload }) => {
        state.processingStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            if (state.userRole !== 'CREATOR') {
              const fullUserData = payload as IGetOneUserResponse;
              const { userData } = fullUserData;
              userData?.assessments && (state.myAssessments = userData.assessments);
            }
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(removeAssessmentAsync.rejected, (state, { error }) => {
        state.processingStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // get all feedbacks
      .addCase(getAllFeedbacksAsync.pending, () => {})
      .addCase(getAllFeedbacksAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const { statusCode, message, foundFeedbacks } = payload;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            foundFeedbacks && (state.feedbacks = foundFeedbacks);
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(getAllFeedbacksAsync.rejected, (state, { error }) => {
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // add one feedback
      .addCase(addOneFeedbackAsync.pending, (state) => {
        state.processingStatus = 'loading';
      })
      .addCase(addOneFeedbackAsync.fulfilled, (state, { payload }) => {
        state.processingStatus = 'idle';
        if (payload) {
          const { statusCode, message } = payload;
          const isSuccess = statusCode === 201;
          state.authMessage = {
            message,
            severity: !isSuccess ? 'negative' : 'positive',
          };
        }
      })
      .addCase(addOneFeedbackAsync.rejected, (state, { error }) => {
        state.processingStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // delete one feedback
      .addCase(deleteOneFeedbackAsync.pending, (state) => {
        state.processingStatus = 'loading';
      })
      .addCase(deleteOneFeedbackAsync.fulfilled, (state, { payload }) => {
        state.processingStatus = 'idle';
        if (payload) {
          const { statusCode, message, foundFeedbacks } = payload as IGetAllFeedbacksResponse;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            foundFeedbacks && (state.feedbacks = foundFeedbacks);
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(deleteOneFeedbackAsync.rejected, (state, { error }) => {
        state.processingStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      })
      // delete all feedbacks
      .addCase(deleteAllFeedbacksAsync.pending, (state) => {
        state.processingStatus = 'loading';
      })
      .addCase(deleteAllFeedbacksAsync.fulfilled, (state, { payload }) => {
        state.processingStatus = 'idle';
        if (payload) {
          const { statusCode, message, foundFeedbacks } = payload as IGetAllFeedbacksResponse;
          const isSuccess = statusCode === 200;
          if (isSuccess) {
            foundFeedbacks && (state.feedbacks = foundFeedbacks);
          } else {
            state.authMessage = {
              message,
              severity: 'negative',
            };
          }
        }
      })
      .addCase(deleteAllFeedbacksAsync.rejected, (state, { error }) => {
        state.processingStatus = 'failed';
        console.error('\x1b[40m\x1b[31m\x1b[1m', error.message);
      });
  },
});

export const { reducer } = userSlice;
