import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import {
  getCurrentLanguage,
  getCurrentRegistrationQuestionNumber,
} from 'redux/slices/main/exports';
import { useAnimateExtension } from './hooks/useAnimate';
import { RegistrationQuestionNumberType } from 'pages/MainPageRemastered/pages/RegistrationPage/components/RegistrationSection/types';
import { getAppliedInfoText, getQuestionSwitcherTitle } from './utils';
import styles from './RegistrationQuestionSwitcher.module.scss';
import { getRegistrationDataApplied } from 'redux/slices/user/exports';

interface Props {
  questionNumber: RegistrationQuestionNumberType;
}

export const RegistrationQuestionSwitcher: FC<Props> = memo(({ questionNumber }) => {
  const indicatorRef = useRef(null);
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const registrationDataApplied = useAppSelector(getRegistrationDataApplied);
  const currentRegistrationQuestionNumber = useAppSelector(getCurrentRegistrationQuestionNumber);
  const isCurrentQuestionOrAlreadyAnswered = questionNumber <= currentRegistrationQuestionNumber;
  const isCurrentQuestion = questionNumber === currentRegistrationQuestionNumber;
  useAnimateExtension(isCurrentQuestionOrAlreadyAnswered, indicatorRef);
  const title = getQuestionSwitcherTitle(questionNumber, currentLanguage);
  const appliedInfoText = getAppliedInfoText(
    questionNumber,
    registrationDataApplied,
    isCurrentQuestion,
  );
  return (
    <div className={styles.RegistrationQuestionSwitcher}>
      <p className={styles.appliedInfoText}>{appliedInfoText}</p>
      <div className={styles.indicator} ref={indicatorRef}></div>
      <p className={styles.title}>{title}</p>
    </div>
  );
});
