import { MainState } from './types';

export const mainSliceInitialState: MainState = {
  currentLanguage: 'en',
  theme: 'dark',
  isFirstLoad: true,
  isAuthModalActive: false,
  isContentContainerHovered: false,
  isUsersSearchResultsShown: false,
  userSearchKey: '',
  foundUsers: [],
  searchUsersStatus: 'idle',
  activeSportSection: 'diary',
  activePetsSection: 'cattery',
  activeHealthSection: 'diary',
  currentlyViewedPage: 'home',
  isLanguageSwitcherRolled: false,
  isPasswordRecoveryMode: false,
  passwordRecoveringEmail: null,
  isPasswordRecoveryProcessStarted: false,
  onlineUsersNicknames: [],
  currentlyViewedAvailableActions: null,
  openedAssessmentRegistration: null,
  currentRegistrationQuestionNumber: 1,
  isRegistrationMayProceed: false,
};
