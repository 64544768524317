import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import { getIsAuth } from 'redux/slices/user/exports';
import { AssistantIntroduction } from './components/AssistantIntroduction/AssistantIntroduction';
import styles from './AssistantPage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const AssistantPage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const assistantPageRef = useRef(null);
  const isAuth = useAppSelector(getIsAuth);
  useAnimateAppearing('assistant' === currentlyViewedPage, assistantPageRef);
  return (
    <div className={styles.AssistantPage} ref={assistantPageRef}>
      {isAuth ? null : <AssistantIntroduction />}
    </div>
  );
});
