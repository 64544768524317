import { FC } from 'react';

import { TextStyled } from 'components/TextStyled/TextStyled';
import { useAppSelector } from 'redux/hooks';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import styles from './AssistantIntroduction.module.scss';
import { language } from 'utils/translation';

export const AssistantIntroduction: FC = () => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  return (
    <div className={styles.AssistantIntroduction}>
      <TextStyled text={language(currentLanguage, 'assistantPlaceholder')} centered />
    </div>
  );
};
