import { FC, memo, useRef } from 'react';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { CurrentlyViewedPageType } from 'redux/types';
import styles from './InfoPage.module.scss';

interface Props {
  currentlyViewedPage: CurrentlyViewedPageType;
}

export const InfoPage: FC<Props> = memo(({ currentlyViewedPage }) => {
  const infoPageRef = useRef(null);
  useAnimateAppearing('info' === currentlyViewedPage, infoPageRef);
  return (
    <div className={styles.InfoPage} ref={infoPageRef}>
      <span style={{ fontWeight: 'bold' }}>info</span>
    </div>
  );
});
