import { CurrentLanguageType, RegistrationQuestionType } from 'types/types';
import { language } from 'utils/translation';

interface IRegistrationInputData {
  registrationInputType: 'email' | 'password' | 'text';
  registrationInputPlaceholder: string;
}

export const getRegistrationInputType = (
  type: RegistrationQuestionType,
  currentLanguage: CurrentLanguageType,
): IRegistrationInputData => {
  const registrationInputPlaceholder = (() => {
    switch (type) {
      case 'email':
        return 'mail@example.com';
      case 'password':
        return language(currentLanguage, 'passwordCap');
      case 'nickname':
        return language(currentLanguage, 'nicknameCap');
    }
  })();

  switch (type) {
    case 'email':
      return { registrationInputType: 'email', registrationInputPlaceholder };
    case 'password':
      return { registrationInputType: 'password', registrationInputPlaceholder };
    case 'nickname':
      return { registrationInputType: 'text', registrationInputPlaceholder };
  }
};
