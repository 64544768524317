import { FC, useRef } from 'react';

import styles from './MainLogoText.module.scss';
import { useAnimateAppearingText } from './hooks/useAnimate';

export const MainLogoText: FC = () => {
  const mainLogoTextRef = useRef(null);
  useAnimateAppearingText(mainLogoTextRef);
  return (
    <p className={styles.MainLogoText} ref={mainLogoTextRef}>
      <span>software</span>
    </p>
  );
};
