import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { EMPTY_STRING } from 'constants/constants';
import { REGISTRATION_DATA_DEFAULTS } from 'redux/slices/user/constants';
import {
  getRegistrationData,
  getRegistrationDataApplied,
  setRegistrationData,
  setRegistrationDataApplied,
} from 'redux/slices/user/exports';

export const useRegistrationData = () => {
  const dispatch = useAppDispatch();
  const registrationData = useAppSelector(getRegistrationData);
  const registrationDataApplied = useAppSelector(getRegistrationDataApplied);
  const isRegistrationDataEmpty = () => {
    return Object.values(registrationData).every((value) => value === EMPTY_STRING);
  };
  const isRegistrationDataAppliedEmpty = () => {
    return Object.values(registrationDataApplied).every((value) => value === EMPTY_STRING);
  };

  const clearRegistrationData = () => {
    dispatch(setRegistrationData(REGISTRATION_DATA_DEFAULTS));
  };
  const clearRegistrationDataApplied = () => {
    dispatch(setRegistrationDataApplied(REGISTRATION_DATA_DEFAULTS));
  };
  const clearAllRegistrationData = () => {
    dispatch(setRegistrationData(REGISTRATION_DATA_DEFAULTS));
    dispatch(setRegistrationDataApplied(REGISTRATION_DATA_DEFAULTS));
  };
  return {
    registrationData,
    registrationDataApplied,
    clearRegistrationData,
    clearRegistrationDataApplied,
    isRegistrationDataEmpty,
    isRegistrationDataAppliedEmpty,
    clearAllRegistrationData,
  };
};
