import { FC, memo, useRef } from 'react';

import { ERROR_ROUND_ICON_SRC } from 'constants/assets-src';
import { useAnimateErrorAppearing } from './hooks/useAnimate';
import styles from './RegistrationDataValidationInfo.module.scss';

interface Props {
  validationError: string;
}

export const RegistrationDataValidationInfo: FC<Props> = memo(({ validationError }) => {
  const validationErrorParagraphRef = useRef(null);
  useAnimateErrorAppearing(!!validationError, validationErrorParagraphRef);
  return (
    <div className={styles.RegistrationDataValidationInfo} ref={validationErrorParagraphRef}>
      <img src={ERROR_ROUND_ICON_SRC} alt="" />
      <p>
        <span>{validationError}</span>
      </p>
    </div>
  );
});
