import { FC } from 'react';

import { CustomText } from './components/CustomText/CustomText';
import { classNames } from 'helpers/classNames';
import styles from './TextStyled.module.scss';

interface Props {
  text: string;
  centered?: boolean;
}

export const TextStyled: FC<Props> = ({ text, centered }) => {
  return (
    <p className={classNames(styles.TextStyled, undefined, { [styles.centered]: centered })}>
      {text.split(' ').map((textWord, index) => {
        if (textWord.startsWith('*')) {
          return <CustomText type="gradient" text={` ${textWord.slice(1)} `} key={index} />;
        }
        if (textWord.startsWith('~')) {
          return <CustomText type="solid" text={` ${textWord.slice(1)} `} key={index} />;
        }
        return ` ${textWord} `;
      })}
    </p>
  );
};
