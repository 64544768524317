import { AVAILABLE_LANGUAGES } from 'constants/constants';
import { CurrentLanguageType, IObjectOfObjects } from 'types/types';

interface ITranslationsObject {
  en: string;
  ru: string;
}

const translations: IObjectOfObjects<ITranslationsObject> = {
  authorization: {
    en: 'authorization',
    ru: 'авторизация',
  },
  assistantPlaceholder: {
    en: "please wait, I'm ~working on it ;)",
    ru: 'погоди, я ~работаю над этим ;)',
  },
  registrationQuestionEmail: {
    en: "~Hi! We will need ~your ~email address to register. Let's point it out:",
    ru: '~Привет! Для регистрации нам потребуется ~ваш ~email. Давайте укажем его:',
  },
  sportAssistantCap: {
    en: 'Sport Assistant',
    ru: 'Спорт Ассистент',
  },
  mySportCap: {
    en: 'My Sport',
    ru: 'Мой спорт',
  },
  infoCap: {
    en: 'Information',
    ru: 'Информация',
  },
  supportCap: {
    en: 'Support',
    ru: 'Поддержать',
  },
  projectsCap: {
    en: 'Projects',
    ru: 'Проекты',
  },
  signInCap: {
    en: 'Sign in',
    ru: 'Войти',
  },
  signUpCap: {
    en: 'Sign up',
    ru: 'Создать',
  },
  cancelCap: {
    en: 'Cancel',
    ru: 'Отмена',
  },
  resetCap: {
    en: 'Reset',
    ru: 'Сбросить',
  },
  project: {
    en: 'project',
    ru: 'проект',
  },
  registrationCap: {
    en: 'Registration',
    ru: 'Регистрация',
  },
  register: {
    en: 'register',
    ru: 'зарегистрировать',
  },
  registerCap: {
    en: 'Register',
    ru: 'Зарегистрировать',
  },
  logInCap: {
    en: 'Log in',
    ru: 'Вход',
  },
  room: {
    en: 'room',
    ru: 'кабинет',
  },
  passwordCap: {
    en: 'Password',
    ru: 'Пароль',
  },
  passwordConfirmCap: {
    en: 'Confirm password',
    ru: 'Подтвердите пароль',
  },
  or: {
    en: 'or',
    ru: 'или',
  },
  authorizationPageTitle: {
    en: "Deguz's World - Authorization",
    ru: 'Мир Deguz - Авторизация',
  },
  userRoomPageTitle: {
    en: "Deguz's World - User's Room",
    ru: 'Мир Deguz - Кабинет пользователя',
  },
  project1PageTitle: {
    en: "Deguz's World - Sport",
    ru: 'Мир Deguz - Спорт',
  },
  project2PageTitle: {
    en: "Deguz's World - Pets",
    ru: 'Мир Deguz - Питомцы',
  },
  project3PageTitle: {
    en: "Deguz's World - Health",
    ru: 'Мир Deguz - Здоровье',
  },
  informationPageTitle: {
    en: "Deguz's World - Information",
    ru: 'Мир Deguz - Информация',
  },
  enterEmailMessageCap: {
    en: 'Enter email',
    ru: 'Введите email',
  },
  enterCorrectEmailMessageCap: {
    en: 'Enter the correct email',
    ru: 'Введите корректный email',
  },
  emailChecking: {
    en: 'email checking',
    ru: 'проверка email',
  },
  aboutProject: {
    en: 'about project',
    ru: 'о проекте',
  },
  nicknameCap: {
    en: 'Nickname',
    ru: 'Никнейм',
  },
  backCap: {
    en: 'Back',
    ru: 'Назад',
  },
  information: {
    en: 'information',
    ru: 'информация',
  },
  passwordsNotMatchCap: {
    en: 'Passwords not match',
    ru: 'Пароли не совпадают',
  },
  enterEmailForLoginCap: {
    en: 'Enter your email to log in',
    ru: 'Введите email для входа в систему',
  },
  enterEmailForRecoveryCap: {
    en: 'Enter your email to recover your password',
    ru: 'Введите email для восстановления пароля',
  },
  enterEmailForRegistrationCap: {
    en: 'Enter email for registration',
    ru: 'Введите email для регистрации',
  },
  enterCorrectEmailAddressCap: {
    en: 'Enter the correct email address',
    ru: 'Введите корректный email адрес',
  },
  specifiedEmailIncorrect: {
    en: 'the specified email is incorrect',
    ru: 'указанный email - некорректный',
  },
  enterPasswordForLoginCap: {
    en: 'Enter the password to log in',
    ru: 'Введите пароль для входа в систему',
  },
  enterPasswordForRegistrationCap: {
    en: 'Enter the password for registration',
    ru: 'Введите пароль для регистрации',
  },
  minPasswordLength8CharsCap: {
    en: 'The minimum password length is 8 characters',
    ru: 'Минимальная длина пароля - 8 символов',
  },
  enterNicknameForRegistrationCap: {
    en: 'Enter a nickname to register',
    ru: 'Введите никнейм для регистрации',
  },
  minNicknameLength2CharsCap: {
    en: 'The minimum nickname length is 2 characters',
    ru: 'Минимальная длина никнейма - 2 символа',
  },
  maxNicknameLength10CharsCap: {
    en: 'The maximum nickname length is 10 characters',
    ru: 'Максимальная длина никнейма - 10 символов',
  },
  logout: {
    en: 'logout',
    ru: 'выйти',
  },
  doYoWantLogoutCap: {
    en: 'Do you want to log out?',
    ru: 'Вы хотите выйти?',
  },
  confirmUsersMasteryCap: {
    en: "Confirm the user's mastery?",
    ru: 'Подтвердить мастерство пользователя?',
  },
  yesCap: {
    en: 'Yes',
    ru: 'Да',
  },
  youSuccessfullyLoggedOut: {
    en: 'You have successfully logged out',
    ru: 'Вы успешно вышли из системы',
  },
  userAvatarAltText: {
    en: "user's avatar",
    ru: 'аватарка пользователя',
  },
  documentConfirmingMasteryAltText: {
    en: 'a document confirming the mastery',
    ru: 'документ, подтверждающий мастерство',
  },
  updateAvatarTitle: {
    en: 'update your avatar',
    ru: 'изменить аватарку',
  },
  profileUpdating: {
    en: 'profile updating',
    ru: 'обновление профиля',
  },
  sport: {
    en: 'sport',
    ru: 'спорт',
  },
  pets: {
    en: 'pets',
    ru: 'питомцы',
  },
  health: {
    en: 'health',
    ru: 'здоровье',
  },
  searchCap: {
    en: 'Search',
    ru: 'Поиск',
  },
  youCap: {
    en: 'You',
    ru: 'Вы',
  },
  trainingDiaryCap: {
    en: 'Training diary',
    ru: 'Дневник тренировок',
  },
  healthDiaryCap: {
    en: 'Health diary',
    ru: 'Дневник здоровья',
  },
  catteryCap: {
    en: 'Cattery',
    ru: 'Питомник кошек',
  },
  nurseryCap: {
    en: 'Cattery',
    ru: 'Питомник',
  },
  later: {
    en: 'later...',
    ru: 'позже...',
  },
  baseTitleCap: {
    en: "Deguz's World",
    ru: 'Мир Deguz',
  },
  authorizationNeededCap: {
    en: 'Authorization needed',
    ru: 'Требуется авторизация',
  },
  trainingDiaryPageDescription: {
    en: '*trainingDiaryCap is a training diary for athletes and coaches, where you can add your workouts and view analytics.',
    ru: '*trainingDiaryCap - это дневник тренировок для спортсменов и тренеров, где Вы можете добавлять свои тренировки и просматривать аналитику. ',
  },
  deguzSportsCap: {
    en: 'Deguz Sports',
    ru: 'Deguz Sports',
  },
  deguzSportsPageDescription: {
    en: '*deguzSportsCap is my personal sports and training project.',
    ru: '*deguzSportsCap - это мой личный проект по спорту и тренировкам.',
  },
  maineCoonCatteryCap: {
    en: 'Maine-coon Cattery',
    ru: 'Питомник Мейн-кунов',
  },
  maineCoonCatteryPageDescription: {
    en: '*maineCoonCatteryCap is a cattery of the Maine Coon breed in the city of Vitebsk, where you can view and choose a pet of this breed.',
    ru: '*maineCoonCatteryCap - это питомник кошек породы Мейн-кун в городе Витебске, где Вы можете просматривать и выбрать себе любимца данной породы.',
  },
  healthDiaryPageDescription: {
    en: '*healthDiaryCap is your diary for registering your health indicators (for example, blood pressure, sugar levels, etc.), where you can view statistics, upload your medical documents and receive recommendations.',
    ru: '*healthDiaryCap - это Ваш дневник для регистрации показателей своего здоровья (например, артериальное давление, уровень сахара и т.п.), где Вы можете просматривать статистику, загружать свои медицинские документы и получать рекомендации.',
  },
  doctor: {
    en: 'doctor',
    ru: 'врач',
  },
  patient: {
    en: 'patient',
    ru: 'пациент',
  },
  sportsmen: {
    en: 'sportsmen',
    ru: 'спортсмен',
  },
  trainer: {
    en: 'trainer',
    ru: 'тренер',
  },
  petOwner: {
    en: 'pet owner',
    ru: 'владелец',
  },
  veterinarian: {
    en: 'veterinarian',
    ru: 'ветеринар',
  },
  noUsersFoundCap: {
    en: 'No users found',
    ru: 'Пользователи не найдены',
  },
  settings: {
    en: 'settings',
    ru: 'настройки',
  },
  informationCap: {
    en: 'Information',
    ru: 'Информация',
  },
  specializationCap: {
    en: 'Specialization',
    ru: 'Специализация',
  },
  roleCap: {
    en: 'Role',
    ru: 'Роль',
  },
  deletionCap: {
    en: 'Deletion',
    ru: 'Удаление',
  },
  masteryCap: {
    en: 'Mastery',
    ru: 'Мастерство',
  },
  enterCurrentPasswordCap: {
    en: 'Enter the current password',
    ru: 'Введите текущий пароль',
  },
  enterNewPasswordCap: {
    en: 'Enter a new password',
    ru: 'Введите новый пароль',
  },
  confirmNewPasswordCap: {
    en: 'Repeat the new password',
    ru: 'Подтвердите новый пароль',
  },
  clearCap: {
    en: 'Clear',
    ru: 'Очистить',
  },
  doYouWantDowngradeYourRoleCap: {
    en: 'Do you want to downgrade your role?',
    ru: 'Вы хотите понизить свою роль?',
  },
  doYouWantDeleteYourAccountCap: {
    en: 'Do you want to delete your account?',
    ru: 'Вы хотите удалить свой аккаунт?',
  },
  downgradeYourRoleCap: {
    en: 'Downgrade your role',
    ru: 'Понизить свою роль',
  },
  search: {
    en: 'search',
    ru: 'поиск',
  },
  enterNewNicknameCap: {
    en: 'Enter a new nickname',
    ru: 'Укажите новый никнейм',
  },
  enterNewEmailCap: {
    en: 'Enter a new email',
    ru: 'Укажите новый email',
  },
  enterNewCityCap: {
    en: 'Enter a new city',
    ru: 'Укажите новый город',
  },
  enterNewCountryCap: {
    en: 'Enter a new country',
    ru: 'Укажите новую страну',
  },
  enterNewName: {
    en: 'Enter a new name',
    ru: 'Укажите новое имя',
  },
  enterNewLastName: {
    en: 'Enter a new last name',
    ru: 'Укажите новую фамилию',
  },
  dd: {
    en: 'dd',
    ru: 'дд',
  },
  mm: {
    en: 'mm',
    ru: 'мм',
  },
  yyyy: {
    en: 'yyyy',
    ru: 'гггг',
  },
  enterCorrectBirthDate: {
    en: 'Enter the correct date of birth',
    ru: 'Укажите корректную дату рождения',
  },
  deleteAccountCap: {
    en: 'Delete Account',
    ru: 'Удалить аккаунт',
  },
  recoverCap: {
    en: 'Recover',
    ru: 'Восстановить',
  },
  checkCap: {
    en: 'Check',
    ru: 'Проверить',
  },
  changeCap: {
    en: 'Change',
    ru: 'Изменить',
  },
  enterCodeCap: {
    en: 'Enter the code',
    ru: 'Введите код',
  },
  minute: {
    en: 'minute',
    ru: 'минута',
  },
  minutes: {
    en: 'minutes',
    ru: 'минут',
  },
  noActionsAvailableCap: {
    en: 'No actions available',
    ru: 'Нет доступных действий',
  },
  masteryAssessmentsCap: {
    en: 'Mastery assessments',
    ru: 'Подтверждения мастерства',
  },
  declaresMastery: {
    en: 'declares mastery',
    ru: 'заявляет мастерство',
  },
  approveCap: {
    en: 'Approve',
    ru: 'Одобрить',
  },
  waitingCap: {
    en: 'Waiting',
    ru: 'Ожидает',
  },
  processing: {
    en: 'processing',
    ru: 'обработка',
  },
  assessmentRegistrationCap: {
    en: 'Assessment registration',
    ru: 'Регистрация подтверждения',
  },
  documentPreview: {
    en: 'document preview',
    ru: 'предварительное изображение документа',
  },
  attachDocument: {
    en: 'attach the document',
    ru: 'приложите документ',
  },
  sendCap: {
    en: 'Send',
    ru: 'Отправить',
  },
  deleteMasteryConfirmationRequestCap: {
    en: 'Delete the mastery confirmation request?',
    ru: 'Удалить запрос подтверждения мастерства?',
  },
  doYouWantToRemoveMastery: {
    en: 'Do you want to remove the mastery?',
    ru: 'Вы хотите удалить мастерство?',
  },
  errorCap: {
    en: 'Error',
    ru: 'Ошибка',
  },
  weLoggingInCap: {
    en: 'We are logging in',
    ru: 'Авторизуемся',
  },
  sendFeedbackCap: {
    en: 'Send feedback',
    ru: 'Отправить отзыв',
  },
  yourNameCap: {
    en: 'Your name',
    ru: 'Ваше имя',
  },
  emailOptionalCap: {
    en: 'Email (optional)',
    ru: 'Email (необязательно)',
  },
  offer: {
    en: 'offer',
    ru: 'предложение',
  },
  remark: {
    en: 'remark',
    ru: 'замечание',
  },
  compliment: {
    en: 'compliment',
    ru: 'похвала',
  },
  feedbacksCap: {
    en: 'Feedbacks',
    ru: 'Отзывы',
  },
  from: {
    en: 'from',
    ru: 'от',
  },
  contentCap: {
    en: 'Content',
    ru: 'Содержание',
  },
};

export const language = (requestLanguage: CurrentLanguageType, wordOrPhrase: string) => {
  return (
    translations?.[wordOrPhrase]?.[
      AVAILABLE_LANGUAGES.find((rightLanguage) => rightLanguage === requestLanguage)
        ? requestLanguage
        : 'en'
    ] || 'no translation :('
  );
};
