import { FC, memo } from 'react';

import { RegistrationQuestionSwitcher } from './components/RegistrationQuestionSwitcher/RegistrationQuestionSwitcher';
import styles from './RegistrationProgressAndSwitcher.module.scss';

export const RegistrationProgressAndSwitcher: FC = memo(() => {
  return (
    <div className={styles.RegistrationProgressAndSwitcher}>
      <RegistrationQuestionSwitcher questionNumber={1} />
      <RegistrationQuestionSwitcher questionNumber={2} />
      <RegistrationQuestionSwitcher questionNumber={3} />
    </div>
  );
});
