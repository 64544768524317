import { FC, memo } from 'react';

import { RegistrationQuestion } from './components/RegistrationQuestion/RegistrationQuestion';
import { RegistrationProgressAndSwitcher } from './components/RegistrationProgressAndSwitcher/RegistrationProgressAndSwitcher';
import styles from './RegistrationSection.module.scss';

export const RegistrationSection: FC = memo(() => {
  return (
    <div className={styles.RegistrationSection}>
      <RegistrationProgressAndSwitcher />
      <RegistrationQuestion type="email" />
    </div>
  );
});
