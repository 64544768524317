import { ChangeEvent, FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getRegistrationData, setRegistrationData } from 'redux/slices/user/exports';
import { getRegistrationInputType } from './utils';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { RegistrationQuestionType } from 'types/types';
import styles from './RegistrationQuestionAnswer.module.scss';

interface Props {
  type?: RegistrationQuestionType;
  validationError?: string;
  isValidatedFieldEmpty?: boolean;
}

export const RegistrationQuestionAnswer: FC<Props> = memo(({ type }) => {
  const registrationQuestionAnswerInput = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const registrationData = useAppSelector(getRegistrationData);
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const currentRegistrationDataValue = registrationData[type];
  const { registrationInputType, registrationInputPlaceholder } = getRegistrationInputType(
    type,
    currentLanguage,
  );
  const updateRegistrationData = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    dispatch(setRegistrationData({ ...registrationData, [type]: value }));
  };
  return (
    <div className={styles.RegistrationQuestionAnswer}>
      <input
        type={registrationInputType}
        placeholder={registrationInputPlaceholder}
        value={currentRegistrationDataValue}
        spellCheck={false}
        onChange={(event) => updateRegistrationData(event)}
        ref={registrationQuestionAnswerInput}
      />
    </div>
  );
});
