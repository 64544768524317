import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import {
  MAIN_LOGO_TEXT_APPEARING_DURATION,
  MAIN_LOGO_TEXT_APPEARING_DELAY,
} from 'components/MainLogoLive/constants';

export const useAnimateAppearingText = (scopeElement: MutableRefObject<HTMLDivElement>) => {
  const { timeline: appearingTextTimeline } = useGSAPTimeline(false, undefined, scopeElement, []);
  appearingTextTimeline &&
    appearingTextTimeline.to(scopeElement.current, {
      opacity: 1,
      duration: MAIN_LOGO_TEXT_APPEARING_DURATION,
      delay: MAIN_LOGO_TEXT_APPEARING_DELAY,
    });
};
